import { zipObj } from 'ramda';

export const QP_BOOKING_HASH = 'QP_BOOKING_HASH';
export const QP_QUOTES_PARAMS = 'QP_QUOTES_PARAMS';

export const FLIGHT_HIGHLIGHTS = [
  'Economy Class',
  'Baggage included',
  'General seat selection',
];

export const GLOBAL_MESSAGE_STORAGE_KEY = 'GLOBAL_MESSAGE_STORAGE_KEY';

export const BOOKABLE_PERIOD = {
  min: 2,
  max: 365,
};
export const MAX_STAY = 29;
export const MAX_TRAVELLERS_COUNT = 9;

export const QFF_AUTH_COOKIE = {
  name: 'lsl_auth_data',
  options: {
    path: '/',
    withoutBasePath: true,
  },
};

export const ORIGINS_CAPITAL_CITIES = [
  {
    name: 'Melbourne (Tullamarine)',
    code: 'MEL',
  },
  {
    name: 'Sydney',
    code: 'SYD',
  },
  {
    name: 'Canberra',
    code: 'CBR',
  },
  {
    name: 'Brisbane',
    code: 'BNE',
  },
  {
    name: 'Perth',
    code: 'PER',
  },
  {
    name: 'Adelaide',
    code: 'ADL',
  },
  {
    name: 'Darwin',
    code: 'DRW',
  },
  {
    name: 'Hobart',
    code: 'HBA',
  },
];

export const SYD = 'SYD';
export const MEL = 'MEL';

export const ORIGINS = [
  ...ORIGINS_CAPITAL_CITIES,
  { name: 'Dubbo', code: 'DBO' },
  {
    name: 'Cairns',
    code: 'CNS',
  },
  {
    name: 'Newcastle - Port Stephens',
    code: 'NTL',
  },
  {
    name: 'Melbourne (Avalon)',
    code: 'AVV',
  },
  {
    name: 'Busselton Margaret River',
    code: 'BQB',
  },
  {
    name: 'Launceston',
    code: 'LST',
  },
  {
    name: 'Mackay',
    code: 'MKY',
  },
  {
    name: 'Whitsunday Coast (Proserpine)',
    code: 'PPP',
  },
  {
    name: 'Townsville',
    code: 'TSV',
  },
  {
    name: 'Sunshine Coast',
    code: 'MCY',
  },
  {
    name: 'Hamilton Island',
    code: 'HTI',
  },
  {
    name: 'Hervey Bay',
    code: 'HVB',
  },
  {
    name: 'Gold Coast',
    code: 'OOL',
  },
  {
    name: 'Ballina Byron',
    code: 'BNK',
  },
  {
    name: 'Uluru (Ayers Rock)',
    code: 'AYQ',
  },
  {
    name: 'Auckland',
    code: 'AKL',
  },
  {
    name: 'Queenstown',
    code: 'ZQN',
  },
  {
    name: 'Christchurch',
    code: 'CHC',
  },
  {
    name: 'Bali',
    code: 'DPS',
  },
  {
    name: 'Fiji',
    code: 'NAN',
  },
  {
    name: 'Phuket',
    code: 'HKT',
  },
  {
    name: 'Singapore',
    code: 'SIN',
  },
  {
    name: 'Bangkok',
    code: 'BKK',
  },
  {
    name: 'Hawaii',
    code: 'HNL',
  },
  {
    name: 'Ho Chi Minh City',
    code: 'SGN',
  },
  {
    name: 'Osaka',
    code: 'KIX',
  },
  {
    name: 'Seoul',
    code: 'ICN',
  },
  {
    name: 'Tokyo',
    code: 'NRT',
  },
];

export const NAVIGATION_LINKS = [
  {
    key: 'Jetstar.com',
    href: 'https://www.jetstar.com/au/en',
    children: 'Jetstar.com',
    external: true,
    target: '_blank',
  },
  {
    key: 'Holidays',
    href: '/',
    active: true,
    children: 'Holidays',
  },
  {
    key: 'Flights',
    href: 'https://www.jetstar.com/au/en/flights',
    children: 'Flights',
  },
  {
    key: 'Hotels',
    href: 'https://www.jetstar.com/au/en/hotels',
    children: 'Hotels',
  },
  {
    key: 'CarHire',
    href: 'https://www.jetstar.com/au/en/car-hire',
    children: 'Car Hire',
  },
];

export const BAGGAGE_TYPES = ['checked', 'carryOn'];
export const CARRY_ON_BAGGAGE_CODES = ['CB0', 'CB07'];
export const OVERSIZED_BAGGAGE_CODES = ['OB01'];
export const CHECKED_BAGGAGE_CODES = ['BG0', 'BG20', 'BG30', 'BG40'];
export const BAGGAGE_CODES = [
  ...CHECKED_BAGGAGE_CODES,
  ...CARRY_ON_BAGGAGE_CODES,
];
export const BAGGAGE_WEIGHTS = [0, 20, 30, 40, 7, 14];
export const BAGGAGE_WEIGHTS_BY_CODE = zipObj(BAGGAGE_CODES, BAGGAGE_WEIGHTS);

export const DEFAULT_CAMPAIGN_ORIGIN = 'MEL';

export const ALL_DESTINATIONS = 'ALL_DESTINATIONS';

export const CONTACT_DETAILS_FIELDS = ['email', 'mobile', 'postcode'];

export const PROPERTY_PAGE_NAV_ITEMS = [
  {
    id: 'photos',
    label: 'Photos',
  },
  {
    id: 'overview',
    label: 'Overview',
  },
  {
    id: 'availability',
    label: 'Check availability',
  },
  {
    id: 'flights',
    label: 'Flights',
  },
  {
    id: 'rooms',
    label: 'Package options',
  },
  {
    id: 'highlights',
    label: 'Highlights',
  },
  {
    id: 'about',
    label: 'About the hotel',
  },
  {
    id: 'policies',
    label: 'Hotel policies',
  },
];

export const DOMESTIC = 'DOMESTIC';
export const INTERNATIONAL = 'INTERNATIONAL';
export const TRANS_TASMAN = 'TRANS_TASMAN';

export const TILE_TYPE = {
  STANDARD_PROPERTY: 'STANDARD_PROPERTY',
  FEATURED_PROPERTY: 'FEATURED_PROPERTY',
  PROCURED_OFFER: 'PROCURED_OFFER',
};

export const CAMPAIGNS_WITHOUT_END_DATE = ['deals', 'last-minute'];

export const CAMPAIGNS_WITHOUT_PER_PERSON_PRICE = ['fly-a-friend-for-free'];

export const FLIGHT_DIRECTION = {
  Departing: 'Departing',
  Returning: 'Returning',
};

export const DEALS_SLUG = 'deals';
export const POINTS_EARN_SLUG = 'bonus-points';

export const PRICING_STRATEGIES = {
  TOTAL: 'total',
  PER_PERSON: 'per-person',
};

export const ANCILLARIES_SURCHARGES = {
  BAGGAGE: 'BF20',
  SEAT_SELECTION: 'STGW',
};

export const ANCILLARIES_SURCHARGE_CODES = Object.values(
  ANCILLARIES_SURCHARGES,
);

export const ERROR_CODES = {
  DATES_MISMATCHED: 'DATES_MISMATCHED',
};
