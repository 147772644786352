import { theme as baseTheme } from '@qga/roo-ui';

export const brandColors = {
  orange: '#FF5115',
  paleOrange: '#FFF6F3',
  darkOrange: '#E13A00',
  blue: '#0692FC',
  yellow: '#FDA108',
  purple: '#3B3697',
  sunset: '#F03C00',
  charcoal: '#222222',
  iron: '#444444',
  steel: '#707070',
  cerulean: '#007DDB',
  grasshopper: '#00C249',
  pink: '#FFEDED',
  lightGreen: '#F2FFF1',
  cream: '#FFF5DF',
  sky: '#E3F3FC',
  white: '#FFFFFF',
  notWhite: '#F7F7F7',
  smoke: '#DEDEDE',
  grey: '#AAAAAA',
  lightGrey: '#EEEEEE',
  red: '#FE0002',
  qantasRed: '#E40000',
  qantasDarkRed: '#B10000',
  mulberry: '#b30177',
  rust: '#DB3A00',
  softGrey: '#F4F4F4',
  qantasGrey: '#F6F6F6',
  darkTertiary: '#FF8600',
};

const uiColors = {
  primary: brandColors.orange,
  secondary: brandColors.blue,
  rating: brandColors.yellow,
  error: brandColors.red,
  clubJetstar: brandColors.purple,
  divider: brandColors.smoke,
  border: brandColors.smoke,
  borderOnFocus: brandColors.blue,
  disabled: brandColors.grey,
  hover: brandColors.darkOrange,
  success: brandColors.grasshopper,
  qantas: brandColors.qantasRed,
  qantasDark: brandColors.qantasDarkRed,
  icon: {
    text: brandColors.white,
    standAlone: brandColors.charcoal,
    inclusion: brandColors.steel,
    body: brandColors.iron,
    info: brandColors.cerulean,
    warning: brandColors.red,
    success: brandColors.grasshopper,
    arrow: brandColors.blue,
    disabled: brandColors.smoke,
  },
  text: {
    heading: brandColors.charcoal,
    body: brandColors.iron,
    placeHolder: brandColors.steel,
    hint: brandColors.steel,
    error: brandColors.red,
    hover: brandColors.cerulean,
    inverse: brandColors.white,
  },
  background: {
    info: brandColors.sky,
    warning: brandColors.cream,
    error: brandColors.pink,
    success: brandColors.lightGreen,
    username: brandColors.sky,
    password: brandColors.sky,
    card: brandColors.white,
    page: brandColors.notWhite,
    calendarDay: brandColors.paleOrange,
    select: brandColors.rust,
    hover: brandColors.softGrey,
    banner: brandColors.qantasGrey,
    backdrop: brandColors.charcoal,
  },
  mediaCounterButton: {
    dark: {
      color: brandColors.white,
      backgroundColor: brandColors.grey,
    },
    light: {
      color: brandColors.steel,
      backgroundColor: brandColors.white,
    },
  },
  mulberry: brandColors.mulberry,
};

const overrideColors = {
  lightOrange: brandColors.cream,
  lightBlue: brandColors.sky,
  brightSun: brandColors.yellow,
  lightGrey: brandColors.lightGrey,
  brand: {
    primary: brandColors.orange,
    secondary: brandColors.blue,
  },
  ui: {
    link: brandColors.blue,
    linkHover: brandColors.cerulean,
    badgeBackground: brandColors.yellow,
    errorBackground: brandColors.pink,
    successBackground: brandColors.lightGreen,
    infoBackground: brandColors.sky,
    error: brandColors.red,
  },
  greys: {
    ...baseTheme.colors.greys,
    smoke: brandColors.smoke,
  },
};

const colors = {
  ...baseTheme.colors,
  ...overrideColors,
  ...uiColors,
};

export default colors;
