import BaseApp from 'next/app';
import Head from 'next/head';
import React from 'react';
import {
  ADOBE_LAUNCH_URL,
  AUTH_API_URL,
  LSL_AUTH_URL,
  LSL_USERNAME,
  REDIRECT_URL,
  OPTIMIZELY_CUSTOM_SNIPPET_URL,
} from '@/config';
import SWRProvider from '@/components/SWRProvider';
import GlobalPreviewIndicator from '@/components/GlobalPreviewIndicator';
import DocumentTitle from '../DocumentTitle';
import Providers from '../Providers';
import SiteMessage from '../SiteMessage';
import JetstarFont from './components/JetstarFont';
import { AuthProvider } from '@experiences-ui/shared/v2/components/AuthContext';
import * as Sentry from '@sentry/nextjs';
import { getUserUuid } from '@experiences-ui/shared/v2/utils/localStorage';
import { OAuthProvider } from '@experiences-ui/shared/v2/utils/oauth';
import ClearDocumentReferrer from '@/v2/components/global/ClearDocumentReferrer';
import Roboto from '@/components/App/components/Roboto';
import { dataLayer } from '@/v2/utils/dataLayer';

export class App extends BaseApp {
  componentDidMount() {
    // Get User ID from local storage
    const userUuid = getUserUuid();

    // Set the Sentry ID
    Sentry.setUser({ id: userUuid });
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <script src={ADOBE_LAUNCH_URL} async />
          <script src={OPTIMIZELY_CUSTOM_SNIPPET_URL} async />
        </Head>
        <DocumentTitle />
        <JetstarFont />
        <Roboto />
        <SWRProvider>
          <Providers>
            <ClearDocumentReferrer />
            <OAuthProvider
              dataLayer={dataLayer}
              config={{
                clientId: LSL_USERNAME,
                qffAuthApiUrl: `${AUTH_API_URL}/au/en`,
                lslAuthApiUrl: LSL_AUTH_URL,
                redirectUri: REDIRECT_URL,
                client: 'jqp',
              }}
            >
              <AuthProvider>
                <GlobalPreviewIndicator />
                <SiteMessage />
                <Component
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...pageProps}
                />
              </AuthProvider>
            </OAuthProvider>
          </Providers>
        </SWRProvider>
      </>
    );
  }
}

export default App;
