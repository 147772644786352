import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from '@qga/roo-ui';
import { PageHeaderProvider } from '@/components/PageHeader/hooks/usePageHeader';
import theme from '../../lib/theme/theme';
import { ProgressBarProvider } from '../ProgressBarContext';
import { UnexpectedErrorModalProvider } from '../UnexpectedErrorModal';
import SessionStorageProvider from '@experiences-ui/shared/v2/components/SessionStorageContext';
import { FeatureFlagProvider } from '@experiences-ui/shared/v2/contexts/featureFlags';
import { OptimizelyWrapperProvider } from '@experiences-ui/shared/v2/contexts/optimizely';

const Providers = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ProgressBarProvider>
      <UnexpectedErrorModalProvider>
        <SessionStorageProvider>
          {/* TODO: remove FeatureFlagProvider once we've switched over from Split */}
          <FeatureFlagProvider brand="jetstar">
            <OptimizelyWrapperProvider>
              <PageHeaderProvider>{children}</PageHeaderProvider>
            </OptimizelyWrapperProvider>
          </FeatureFlagProvider>
        </SessionStorageProvider>
      </UnexpectedErrorModalProvider>
    </ProgressBarProvider>
  </ThemeProvider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
